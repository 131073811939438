export interface Res<T> {
  data: T;
  msg: string;
  status: number;
  total?: number;
}

export default function http (url: any, opts: any) {

  // const s = process.env.NODE_ENV === 'production' ? location.origin : 'https://timapi2.dsmai.com'
  // const s = process.env.NODE_ENV === 'production' ? location.origin : 'https://lg.dsmai.com'
  const s = process.env.NODE_ENV === 'production' ? location.origin : 'http://192.168.3.102:6002'

  return new Promise((resolve, reject) => {
    fetch(s + url, opts)
      .then((res) => {
        return res.json()
      })
      .then((res: any) => {
        if (res.code === 120) {
          window.open(`${location.origin}${location.pathname}#/login`, '_self')
        }
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
