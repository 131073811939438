export function millisecondToDate (msd: number) {
  if (isNaN(msd)) {
    return 0
  }
  const seconds = Number((msd / 1000).toFixed(1))
  const minutes = Number((msd / (1000 * 60)).toFixed(1))
  const hours = Number((msd / (1000 * 60 * 60)).toFixed(1))
  const days = Number((msd / (1000 * 60 * 60 * 24)).toFixed(1))

  if (seconds < 60) {
    return seconds + '秒'
  } else if (minutes < 60) {
    return minutes + '分钟'
  } else if (hours < 24) {
    return hours + '小时'
  } else {
    return days + '天'
  }
}

export function millisecondToDateAppKeyUse (msd: number) {
  if (isNaN(msd)) {
    return 0
  }
  const seconds = Number((msd / 1000).toFixed(1))
  const minutes = Number((msd / (1000 * 60)).toFixed(1))
  const hours = Number((msd / (1000 * 60 * 60)).toFixed(1))

  if (seconds < 60) {
    return seconds + '秒'
  } else if (minutes < 60) {
    return minutes + '分钟'
  } else {
    return hours + '小时'
  }
}

export function millisecondToDateAppKeyUseNoCompany (msd: number) {
  if (isNaN(msd)) {
    return
  }
  const minutes = Number((msd / (1000 * 60)).toFixed(1))
  const hours = Number((msd / (1000 * 60 * 60)).toFixed(1))

  if (minutes < 60) {
    return ['分钟', minutes]
  } else {
    return ['小时', hours]
  }
}

export function format_date (date: any, format: string, flag_utc?: string) {
  if (!format) {
    format = 'YYYY-MM-DD hh:mm:ss.SSS'
  }
  var msec = ('00' + ((flag_utc) ? date.getUTCMilliseconds() : date.getMilliseconds())).slice(-3)
  var msec_index = 0
  
  if (flag_utc) {
    format = format
      .replace( /YYYY/g, date.getUTCFullYear() )
      .replace( /MM/g, ( '0' + ( 1 + date.getUTCMonth() ) ).slice( -2 ) )
      .replace( /DD/g, ( '0' + date.getUTCDate() ).slice( -2 ) )
      .replace( /hh/g, ( '0' + date.getUTCHours() ).slice( -2 ) )
      .replace( /mm/g, ( '0' + date.getUTCMinutes() ).slice( -2 ) )
      .replace( /ss/g, ( '0' + date.getUTCSeconds() ).slice( -2 ) )
      .replace( /S/g, function ( all ) {
        return msec.charAt( msec_index ++ )
      })
  } else {
    format = format
      .replace( /YYYY/g, date.getFullYear() )
      .replace( /MM/g, ( '0' + ( 1 + date.getMonth() ) ).slice( -2 ) )
      .replace( /DD/g, ( '0' + date.getDate() ).slice( -2 ) )
      .replace( /hh/g, ( '0' + date.getHours() ).slice( -2 ) )
      .replace( /mm/g, ( '0' + date.getMinutes() ).slice( -2 ) )
      .replace( /ss/g, ( '0' + date.getSeconds() ).slice( -2 ) )
      .replace( /S/g, function ( all ) {
        return msec.charAt( msec_index ++ )
      })
  }
  return format
}
